import { FC, useEffect } from "react";
import { useParams } from "react-router";
import CryptoJS from "crypto-js";
import useQuery from "../hooks/useQuery";

const Redirector: FC = () => {
  const { id } = useParams();
  const { utm_id } = useQuery();

  useEffect(() => {
    if (id && id.length > 0) {
      document.title = id;
      const url = CryptoJS.AES.decrypt(
        id,
        process.env.REACT_APP_PRESSPOOL_AES_KEY as string
      ).toString(CryptoJS.enc.Utf8);
      window.open(url, "_self");
    } else if (utm_id) {
      fetch(
        `https://presspool-backend.onrender.com/data/getCampaignUrlById/${utm_id}`
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          const { page_url } = data;
          window.open(page_url, "_self");
        });
    }
  }, [id, utm_id]);

  return <div></div>;
};

export default Redirector;
